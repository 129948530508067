import { useQuery } from 'react-query'
import { MINUTE } from 'src/constants'
import { apiService } from 'src/services/graphapi'
import { SgQueryKey } from './Shared'

export const getServicePlans = async (skuId?: string) => {
  const response = await apiService.skus_GetServicePlans(skuId || '')
  return response
}

export function useServicePlans(skuId?: string) {
  return useQuery([SgQueryKey.servicePlans, skuId], () => getServicePlans(skuId), {
    staleTime: 5 * MINUTE,
    enabled: skuId !== undefined && skuId !== '',
  })
}
